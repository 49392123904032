<template>
  <div class="wallet-upgrade-account">
    <div class="container-fluid mt--5">
      <div class="card">
        <div class="card-header">
          <h3 class="mp-0">Upgrade your account</h3>
        </div>
        <div class="card-body">
          <img style="width: 100%" src="/img/theme/upgrade.jpg" />
          <div class="text-center p-4">
            <button
              class="btn mb-0 btn-success btn-lg"
              @click="dialogVisible = true"
            >
              Upgrade Now!
            </button>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Upgrade to Plus Account"
      :visible.sync="dialogVisible"
      width="50%"
    >
      <div class="mb-4">Please select currency to create Plus account</div>
      <el-checkbox-group v-model="formData.currency">
        <el-checkbox border label="GBP"></el-checkbox>
        <el-checkbox border label="EUR"></el-checkbox>
        <el-checkbox border label="USD"></el-checkbox>
      </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" :disabled="loading" :loading="loading" @click="upgrade"
          >Confirm</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import WalletApi from '@/api/wallet/wallet'
export default {
  data() {
    return {
      dialogVisible: false,
      formData: { currency: [] },
      loading: false
    };
  },
  methods: {
    upgrade() {
      this.loading = true;
      WalletApi.upgradeAccount(this.formData).then(({ result, message }) => { 
        this.loading = false;
        if (result) {
          this.dialogVisible = false;
          this.formData.currency = [];
          this.$swal("Message", message, "success");
        } else { 
          this.$swal("Message", message, "error");
        }
      })
    }
  }
};
</script>

<style>
</style>